.one-third-height {
  height: calc(var(--vh) * 33);
}

.one-quarter-height {
  height: calc(var(--vh) * 25);
}

.fifteen-height {
  height: calc(var(--vh) * 15);
}

.big-height {
  height: calc(var(--vh) * 60);
  justify-content: center;
}
